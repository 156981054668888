import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';

import { rootService } from '../../../v2/core/business';

import Suggest from '../../../components/Suggest';

import styles from '../styles/form.module.scss';

const SelectAnalytics = observer(({
  validation = '',
  analytics,
  onSelect = () => {},
  value,
  labelTheme = '',
}) => {
  const [currentLabel, setCurrentLabel] = useState(value.Name ? value.Name : '');
  const [suggests, setSuggests] = useState([]);
  const [hasFocused, setHasFocused] = useState(false);
  const inputRef = useRef(null);

  const fetchSuggests = async (query) => {
    await rootService.services.analytics.autocompleteAnalytics(analytics.AccountId, analytics.Id, query, setSuggests);
  };

  useEffect(() => {
    const handleFocus = () => {
      if (!hasFocused) {
        setHasFocused(true);
      }
    };
    if (hasFocused) {
      fetchSuggests(currentLabel);
    }

    const inputElement = inputRef.current?.querySelector('input');
    if (inputElement) {
      inputElement.addEventListener('focus', handleFocus);
    }

    return () => {
      if (inputElement) {
        inputElement.removeEventListener('focus', handleFocus);
      }
    };
  }, [hasFocused, currentLabel]);

  const handleChangeLabel = (value) => {
    setCurrentLabel(value);
  };

  return (
    <div className={ styles['col-1-3'] } ref={ inputRef }>
      <Suggest
        title={ analytics.Name }
        valid={ validation }
        suggests={ suggests }
        currentLabel={ currentLabel }
        onSelect={ onSelect }
        onChangeLabel={ handleChangeLabel }
        labelTheme={ labelTheme }
      />
    </div>
  );
});

SelectAnalytics.propTypes = {
  onSelect: PropTypes.func.isRequired,
  validation: PropTypes.string,
  analytics: PropTypes.object.isRequired,
};

SelectAnalytics.defaultProps = { validation: '' };

export default SelectAnalytics;
